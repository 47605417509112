<template>
  <div class="companySelectTitle" id="companySelectTitle">
  <div class="house-info">
    <div style="float: left;" class="title">
      <Tooltip theme="light" max-width="900" style="width: 100%" :content="selectedCompantName" word-wrap
               placement="bottom">{{ selectedCompantName }}</Tooltip>
    </div>
    <Button title="切换企业" size="default" style="color:#333333;font-size: 20px;" ghost @click="handleOpenModal">
      <Icon type="md-swap" />
    </Button>
    <Tag size="large" class="selfBtn" type="dot" color="#398EFF" v-if="componeyData.sszx">
      {{componeyData.sszx}}
    </Tag>
    <Tag size="large" class="selfBtn" type="dot" color="#2FC83D" v-if="componeyData.ssgs">
      {{ componeyData.ssgs}}
    </Tag>
    <Tag size="large" class="selfBtn" type="dot" color="#9E9EFF"
         v-if="componeyData.ssqk  && componeyData.ssqk != '非上市'">
      {{ componeyData.ssqk}}
    </Tag>
  </div>
  <Modal v-model="modal_info" :mask-closable='false' :closable='false' scrollable title mask @on-ok="handleOk"
         @on-cancel="handleCancel">
    <div class="companySelect">
      <Input v-model="q" placeholder="输入关键字搜索" style="width: 400px" search @input="handleSearch"
             @on-enter="handleSearch" />
      <Button style="margin-left: 20px;" type="info" @click="handleSearch">搜索</Button>
      <Row class="type_content">
        <Col span="24">
          <Card class="type_box">
            <div>
              <Row>
                <Col span="8">
                  <RadioGroup v-model="type" @on-change="handleChangeType" class="radio_type" vertical>
                    <Radio :label="item.name" v-for="(item, index) in list" :key="index">
                      <span>{{ item.name }}</span>
                    </Radio>
                  </RadioGroup>
                </Col>
                <Col span="16">
                  <div class="type_class">
                    <template v-for="(item, index) in classList">
                      <div :class="{ active: id == item.id }" :key="index"
                           @click="() => handleClickComponey(item.gsmc,item.id,item.gpmc)">{{ item.gsmc }}
                      </div>
                    </template>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  </Modal>
  </div>
</template>

<script>
	import {
		enterprise_dim_dimClassify,
	} from "@/api/reHouseEnterprise";
  import {mapState} from "vuex";
	export default {
		name: 'CompanySelect',
		props: ['qylx'],
    computed: {
      ...mapState({
        componeyData(state) {
          return state.reHouseEnterprise.componeyData;
        },
        selectedCompantName(state) {
          return state.reHouseEnterprise.selectedCompantName;
        }
      }),
    },
		data() {
			return {
        modal_info: false,
        selectData: {},
				q: '',
				classList: [],
				list: [],
				type: '',
				id: '',
			}
		},
		mounted() {
			this.handleSearch()
		},
		methods: {
      handleOpenModal() {
        this.modal_info = true
      },
      handleOk() {
        this.$store.dispatch('reHouseEnterprise/getComponeyData', this.selectData)
        this.modal_info = false
      },
      handleCancel() {
        this.modal_info = false
      },
			handleSearch() {
				let params = {
					q: this.q,
					qylx: this.qylx,
				}
				enterprise_dim_dimClassify(params).then(data => {
					let listArray = data.filter((item) => item.list.length > 0);
					this.list = listArray;
					this.type = listArray[0] ? listArray[0].name : "";
					this.classList = listArray[0] ? listArray[0].list : [];
				})
			},
			handleChangeType(name) {
				const {
					list
				} = this;
				list.map((item) => {
					if (item.name === name) {
						this.classList = item.list;
					}
				});
			},
			handleClickComponey(name, id, gpmc) {
				this.id = id
				let res = {
					name,
					id,
					gpmc,
				}
				this.selectData = res;
				this.$emit('handleClickComponey',res)			
			}
		}
	}
</script>

<style scoped lang="scss">
.companySelect {
  width: 100%;
  height: 100%;
}
	.type_box {
		border: 1px solid #d6d7d9;
		background: rgba(246, 249, 255, 1);
		margin-left: 10px;
	}

	.type_content {
		padding: 20px 0 0 0;
		margin-left: -10px;
	}

	.radio_type .ivu-radio-wrapper {
		padding: 10px 0;
	}

	.type_class {
		height: 230px;
		overflow: auto;
		background: #fff;
		border-radius: 2px;
		border: 1px solid #d6d7d9;
		padding: 10px 20px;
	}

	.type_class>div {
		padding-top: 5px;
		padding-bottom: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}

	.type_class>div:hover,
	.type_class>div.active {
		color: #00b6ff;
	}


.title {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 40px;
}


.type_header {
  border-radius: 2px;
  border: 1px solid rgba(214, 215, 217, 1);
  font-size: 16px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  margin-bottom: 10px;
}

.type_header:hover,
.type_header.active {
  background: #00b6ff;
  color: #fff;
}

.house-info {
  padding: 20px 20px 0;
  width: 100%;
}

.right-box {
  overflow: inherit;
}

.type_content {
  padding: 20px 0 0 0;
  margin-left: -10px;
}

.type_box {
  border: 1px solid #d6d7d9;
  background: rgba(246, 249, 255, 1);
  margin-left: 10px;
}

.type_class {
  height: 230px;
  overflow: auto;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #d6d7d9;
  padding: 10px 20px;
}

.type_class>div {
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.type_class>div:hover,
.type_class>div.active {
  color: #00b6ff;
}

.radio_type .ivu-radio-wrapper {
  padding: 10px 0;
}

.selfBtn {
  margin: 0 5px;
  border-radius: 8px;
}
</style>
