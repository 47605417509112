<template>
  <keep-alive>
    <div>
      <CompanySelect qylx='房企'></CompanySelect>
      <router-view></router-view>
    </div>
  </keep-alive>
</template>

<script>
import CompanySelect from './components/companySelect.vue';

export default {
  components: {
    CompanySelect
  },
  name: 'reHouseEnterprise',
  data() {
    return {
      modal_info: false,
      selectData: {},
    }

  },
}
</script>
<style scoped>
</style>
